<template>
  <div>
    <connect-modal :show="showMollieComponent" :width="'505px'" bkcolor="#F7F7F7">
      <div class="price-list-mollie-close-button" style="margin-left: auto" @click="onMollieFormClose">
        <close-circle-icon class="price-list-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <mollie
        v-if="selectedPlan"
        :selectedPlan="selectedPlan"
        :subscriptionPlanId="selectedPlan.id"
        :isFirstInvoice="isFirstInvoice"
        :isModal="true"
        :supportedPaymentMethods="supportedPaymentMethods"
      ></mollie>
    </connect-modal>

    <vs-popup :title="changePlanConfirmationTitle" :active.sync="showConfirmation">
      <p class="mb-2" v-if="selectedPlan && selectedPlan.level > this.CURRENT_PLAN_LEVEL">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <p class="mb-2" v-else>
        {{ DOWNGRADED_FROM }}
      </p>
      <vs-button @click="onConfirmChangePlan" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseChangePlan" type="border" color="#e8546f">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>

    <vs-popup :title="changePlanConfirmationTitle" :active.sync="showMollieConfirmation">
      <p class="mb-2" v-if="selectedPlan && selectedPlan.level > this.CURRENT_PLAN_LEVEL">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <p class="mb-2" v-if="companyData && companyData.paymentStatus === 'on-trial'">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <p class="mb-2" v-else>
        {{ DOWNGRADED_FROM }}
      </p>

      <vs-button @click="onConfirmChangeMolliePlan" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseChangeMolliePlan" type="border" color="#e8546f">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>

    <vs-popup :title="changePlanConfirmationTitle" :active.sync="showMollieUpgradeConfirmation">
      <p class="mb-2" v-if="selectedPlan && selectedPlan.level > this.CURRENT_PLAN_LEVEL">{{ $t('vue.confirmQuestion') }}</p>

      <vs-button @click="onConfirmUpgradeMolliePlan" color="#3034F7" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseUpgradeMolliePlan" type="border" color="#3034F7">{{ $t('vue.cancelChangePlan') }}</vs-button>

      <Promocode />
    </vs-popup>

    <vs-popup :title="$t('pricing.cancelSubscription')" :active.sync="showCancelConfirmation">
      <p class="mb-2">
        {{ $t('pricing.cancelSubscriptionMessage') }}
      </p>
      <vs-button @click="onConfirmCancelPlan" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseCancelPlan" type="border" color="#e8546f">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>
    <vs-popup :title="$t('pricing.cancelSubscription')" :active.sync="showMollieCancelConfirmation">
      <p class="mb-2">
        {{ $t('pricing.cancelSubscriptionMessage') }}
      </p>
      <vs-button @click="onConfirmCancelMolliePlan" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click="onCloseCancelMolliePlan" type="border" color="#e8546f">{{ $t('vue.cancelChangePlan') }}</vs-button>
    </vs-popup>
    <vx-card class="p-0">
      <vs-row class="mt-5">
        <vs-col vs-type="flex">
          <div class="price-list">
            <div class="price-list__heading">
              <h3 class="price-list__heading__title">{{ $t('pricing.chooseTheRightPlanForYou') }}</h3>
            </div>

            <template v-if="!isLoadingSubscriptionPlans && filteredSubscriptionPlans.length">
              <div class="price-list__plans">
                <div class="pricing-plans__heading">
                  <div
                    class="pricing-plans__heading__button"
                    :class="{
                      'pricing-plans__heading__button--current': !isYearly
                    }"
                    @click="() => setPlanInterval(false)"
                  >
                    <div class="pricing-plans__heading__button__title">{{ $t('pricing.monthly') | capitalizeFirstLetter }}</div>
                    <div class="pricing-plans__heading__button__label">{{ $t('pricing.noSavings') }}</div>
                  </div>

                  <div
                    class="pricing-plans__heading__button"
                    :class="{
                      'pricing-plans__heading__button--current': isYearly
                    }"
                    @click="() => setPlanInterval(true)"
                  >
                    <div class="pricing-plans__heading__button__title">{{ $t('pricing.yearly') | capitalizeFirstLetter }}</div>
                    <div class="pricing-plans__heading__button__label">{{ '10%' | generateRightTranslations($i18n.locale) }}</div>
                  </div>
                </div>

                <div class="price-list__plans-list" v-if="!isOpenedFeaturesListOnMobile">
                  <div v-for="plan in filteredSubscriptionPlans" class="price-list__plans-list__item" :key="plan.id">
                    <div
                      class="price-list__plans-list__item__heading"
                      :class="{
                        'price-list__plans-list__item__heading--active': plan.isCurrentPlan
                      }"
                    >
                      <div class="price-list__plans-list__item__heading__title">{{ plan.title }}</div>

                      <div v-if="plan.isNewPlan" class="price-list__plans-list__item__heading__label-summary">
                        {{ ACTIVE_FROM }}
                      </div>
                    </div>

                    <div class="price-list__plans-list__item__body">
                      <!-- <div v-if="SHOW_PLAN_AS_FREE(plan.id)" class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency"></div>
                        <div class="pricing-plans__list__item__content__price__amount">{{ $t('features.freeOfCharge') }}</div>
                      </div> -->
                      <div class="pricing-plans__list__item__content__price">
                        <div v-if="!plan.isCustomPrice" class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div
                          class="pricing-plans__list__item__content__price__amount"
                          :class="{ 'pricing-plans__list__item__content__price__amount--custom-price': plan.isCustomPrice }"
                        >
                          {{ plan.isCustomPrice ? $t(plan.quote) : planPrice(plan) }}
                          <span v-if="!plan.isCustomPrice" class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div>
                      </div>

                      <div class="price-list__plans-list__item__heading__cost">{{ plan.isCustomPrice ? $t(plan.team) : $t(plan.users) }}</div>

                      <div class="price-list__plans-list__item__heading__label-action" :class="SET_PLAN_CLASS(plan, 'heading-label')">
                        <div
                          v-if="!checkIsAbilityToUpdatePlan(plan)"
                          class="price-list__plans-list__item__heading__label-action__text"
                          :class="{
                            'price-list__plans-list__item__heading__label-action__text--disabled':
                              HAS_PADDLE_SUBSCRIPTION || HAS_CANCELLED_PADDLE_MOLLIE_SUBSCRIPTION
                          }"
                          @click="onChoosePlan(plan)"
                        >
                          <template v-if="plan.isCurrentPlan">{{ $t('pricing.currentPlan') }}</template>
                          <template v-else-if="plan.isNewPlan">{{ $t(planChangeText(plan)) }}</template>
                          <template v-else>{{ $t(planChangeText(plan)) }} </template>
                        </div>
                      </div>

                      <div class="price-list__plans-list__item__downgrade" v-if="plan.isNewPlan && DOWNGRADED_FROM_TEXT">
                        {{ DOWNGRADED_FROM_TEXT }}
                      </div>

                      <ul class="price-list__plans-list__item__body__options-list">
                        <li
                          v-for="option in plan.newOptions"
                          class="price-list__plans-list__item__body__options-list__item"
                          :class="{ 'price-list__plans-list__item__body__options-list__item--boldify': option.boldify }"
                          :key="option.id"
                        >
                          <ThinCheckIcon class="price-list__plans-list__item__body__options-list__item__icon" />
                          {{ option.description ? option.description : '' }}
                        </li>

                        <li v-if="plan.isEnterprisePlan" class="price-list__plans-list__item__body__options-list__item">
                          <AmpersandIcon class="price-list__plans-list__item__body__options-list__item__icon" />
                          {{ $t('pricing.more') }}
                        </li>
                      </ul>

                      <div class="price-list__plans-list__item__body__optional" v-if="plan.title && plan.title.toLowerCase() !== 'free'">
                        <div class="price-list__plans-list__item__body__optional__position">
                          <span class="price-list__plans-list__item__body__optional--header">
                            {{ $t('vue.optional') }}
                          </span>
                          <ul class="price-list__plans-list__item__body__options-list__vega-option">
                            <li class="price-list__plans-list__item__body__options-list__item price-list__plans-list__item__body__options-list__item">
                              <vs-icon size="18px" icon="add" icon-pack="material-icons" class="price-list__plans-list__item__body__options-list__item__icon" />
                              <span>{{ $t('pricing.aiOption') }}</span>
                            </li>
                            <li class="price-list__plans-list__item__body__options-list__item price-list__plans-list__item__body__options-list__item">
                              <vs-icon size="18px" icon="add" icon-pack="material-icons" class="price-list__plans-list__item__body__options-list__item__icon" />
                              <span>{{ $t('pricing.cbOption') }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile"
                      v-if="plan.title && plan.title.toLowerCase() === 'basic'"
                    >
                      <div class="pricing-plans__features-list-toggler__button" @click="isOpenedBasicFeaturesList = !isOpenedBasicFeaturesList">
                        {{ isOpenedBasicFeaturesList ? $t('features.hideAllBasicFeatures') : $t('features.seeAllBasicFeatures') }}

                        <img
                          v-if="isOpenedBasicFeaturesList"
                          src="@/assets/images/register-funnel/minus-circle.svg"
                          alt="Hide list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                        <img
                          v-else
                          src="@/assets/images/register-funnel/plus-circle.svg"
                          alt="Open list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                      </div>
                    </div>
                    <div
                      class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile"
                      v-else-if="plan.title && plan.title.toLowerCase() === 'pro'"
                    >
                      <div class="pricing-plans__features-list-toggler__button" @click="isOpenedProFeaturesList = !isOpenedProFeaturesList">
                        {{ isOpenedProFeaturesList ? $t('features.hideAllProFeatures') : $t('features.seeAllProFeatures') }}

                        <img
                          v-if="isOpenedProFeaturesList"
                          src="@/assets/images/register-funnel/minus-circle.svg"
                          alt="Hide list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                        <img
                          v-else
                          src="@/assets/images/register-funnel/plus-circle.svg"
                          alt="Open list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                      </div>
                    </div>
                    <div class="pricing-plans__features-list-toggler pricing-plans__features-list-toggler--mobile" v-else>
                      <div class="pricing-plans__features-list-toggler__button" @click="isOpenedEnterpriseFeaturesList = !isOpenedEnterpriseFeaturesList">
                        {{ isOpenedEnterpriseFeaturesList ? $t('features.hideAllEnterpriseFeatures') : $t('features.seeAllEnterpriseFeatures') }}

                        <img
                          v-if="isOpenedEnterpriseFeaturesList"
                          src="@/assets/images/register-funnel/minus-circle.svg"
                          alt="Hide list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                        <img
                          v-else
                          src="@/assets/images/register-funnel/plus-circle.svg"
                          alt="Open list"
                          class="pricing-plans__features-list-toggler__button__icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pricing-plans__features-list-toggler">
                <div class="pricing-plans__features-list-toggler__button" @click="isOpenedFeaturesList = !isOpenedFeaturesList">
                  {{ isOpenedFeaturesList ? $t('features.hideAllFeatures') : $t('features.seeAllFeatures') }}

                  <img
                    v-if="isOpenedFeaturesList"
                    src="@/assets/images/register-funnel/minus-circle.svg"
                    alt="Hide list"
                    class="pricing-plans__features-list-toggler__button__icon"
                  />
                  <img
                    v-else
                    src="@/assets/images/register-funnel/plus-circle.svg"
                    alt="Open list"
                    class="pricing-plans__features-list-toggler__button__icon"
                  />
                </div>
              </div>

              <div v-if="isOpenedFeaturesList" class="pricing-plans__features">
                <div class="pricing-plans__features__heading">
                  <div class="pricing-plans__features__heading__list">
                    <!-- <div class="pricing-plans__features__heading__list__item">
                      <h5 class="pricing-plans__features__heading__list__item__title">{{ $t('features.free') }}</h5>
                      <div class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency"></div>
                        <div class="pricing-plans__list__item__content__price__amount">{{ $t('features.freeOfCharge') }}</div>
                      </div>
                    </div> -->

                    <div class="pricing-plans__features__heading__list__item">
                      <h5 class="pricing-plans__features__heading__list__item__title">Basic</h5>
                      <div v-if="SHOW_PLAN_AS_FREE(isYearly ? 'M_BASIC_ANNUAL' : 'M_BASIC_MONTHLY')" class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency"></div>
                        <div class="pricing-plans__list__item__content__price__amount">Free</div>
                      </div>
                      <div v-else class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? basicPriceAnnual : basicPriceMonthly }}
                          <span class="pricing-plans__list__item__content__price__amount__period">/mo </span>
                        </div>
                      </div>
                    </div>

                    <div class="pricing-plans__features__heading__list__item">
                      <h5 class="pricing-plans__features__heading__list__item__title">Pro</h5>

                      <div class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? proPriceAnnual : proPriceMonthly }}
                          <span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div>
                      </div>
                    </div>

                    <div class="pricing-plans__features__heading__list__item">
                      <h5 class="pricing-plans__features__heading__list__item__title">Enterprise</h5>

                      <div class="pricing-plans__list__item__content__price">
                        <!-- <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? enterprisePriceAnnual : enterprisePriceMonthly }}
                          <span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div> -->

                        <div class="pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price">
                          {{ $t('pricing.customQuote') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pricing-plans__features-props">
                  <div v-for="(featureSection, featureSectionIndex) in featuresList" :key="featureSection.title">
                    <div
                      class="pricing-plans__features-props__item-heading"
                      :class="{
                        'pricing-plans__features-props__item-heading--rounded': featureSectionIndex === 0
                      }"
                    >
                      {{ featureSection.title }}
                    </div>

                    <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
                      <div class="pricing-plans__features-props__item__value">
                        <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                        <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
                      </div>

                      <div class="pricing-plans__features-props__item__marks-list">
                        <!-- <div class="pricing-plans__features-props__item__marks-list__item">
                          <template v-if="feature.isCustomField">
                            {{ feature.customTextFree }}
                          </template>

                          <template v-else>
                            <ThinCheckIcon v-if="feature.isFree" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                            <img
                              v-else
                              src="@/assets/images/register-funnel/cross.svg"
                              alt="Check"
                              class="pricing-plans__features-props__item__marks-list__item__icon"
                            />
                          </template>
                        </div> -->
                        <div class="pricing-plans__features-props__item__marks-list__item">
                          <template v-if="feature.isCustomField">
                            {{ feature.customTextBasic }}
                          </template>

                          <template v-else>
                            <ThinCheckIcon v-if="feature.isBasic" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                            <img
                              v-else
                              src="@/assets/images/register-funnel/cross.svg"
                              alt="Check"
                              class="pricing-plans__features-props__item__marks-list__item__icon"
                            />
                          </template>
                        </div>
                        <div class="pricing-plans__features-props__item__marks-list__item">
                          <template v-if="feature.isCustomField">
                            {{ feature.customTextPro }}
                          </template>

                          <template v-else>
                            <ThinCheckIcon v-if="feature.isPro" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                            <img
                              v-else
                              src="@/assets/images/register-funnel/cross.svg"
                              alt="Check"
                              class="pricing-plans__features-props__item__marks-list__item__icon"
                            />
                          </template>
                        </div>
                        <div class="pricing-plans__features-props__item__marks-list__item">
                          <template v-if="feature.isCustomField">
                            {{ feature.customTextEnterprise }}
                          </template>

                          <template v-else>
                            <ThinCheckIcon v-if="feature.isEnterprise" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                            <img
                              v-else
                              src="@/assets/images/register-funnel/cross.svg"
                              alt="Check"
                              class="pricing-plans__features-props__item__marks-list__item__icon"
                            />
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isOpenedFeaturesListOnMobile" class="pricing-plans__features-mobile-list">
                <div class="pricing-plans__features-mobile-list__item">
                  <div class="pricing-plans__features-mobile-list__item__heading">
                    <div class="pricing-plans__features-mobile-list__item__heading__main">
                      <div class="pricing-plans__features-mobile-list__item__heading__title">Basic</div>
                      <div class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? basicPriceAnnual : basicPriceMonthly
                          }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="isOpenedBasicFeaturesList && !checkIsAbilityToUpdatePlan(getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY))"
                      class="price-list__plans-list__item__heading__label-action__text"
                      @click="onChoosePlan(getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY))"
                    >
                      <template v-if="getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY).isCurrentPlan">{{ $t('pricing.currentPlan') }}</template>
                      <template v-else-if="getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY).isNewPlan">{{
                        $t(planChangeText(getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY)))
                      }}</template>
                      <template v-else>{{ $t(planChangeText(getCurrentPlanById(isYearly ? M_BASIC_ANNUAL : M_BASIC_MONTHLY))) }} </template>
                    </div>
                  </div>

                  <div v-if="isOpenedBasicFeaturesList" class="pricing-plans__features-props">
                    <div v-for="featureSection in featuresList" :key="featureSection.title">
                      <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

                      <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
                        <div class="pricing-plans__features-props__item__value">
                          <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                          <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
                        </div>

                        <div class="pricing-plans__features-props__item__marks-list">
                          <div class="pricing-plans__features-props__item__marks-list__item">
                            <template v-if="feature.isCustomField">
                              {{ feature.customTextBasic }}
                            </template>

                            <template v-else>
                              <ThinCheckIcon v-if="feature.isBasic" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                              <img
                                v-else
                                src="@/assets/images/register-funnel/cross.svg"
                                alt="Check"
                                class="pricing-plans__features-props__item__marks-list__item__icon"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pricing-plans__features-mobile-list__item pricing-plans__features-mobile-list__item--popular">
                  <div class="pricing-plans__features-mobile-list__item__heading">
                    <div class="pricing-plans__features-mobile-list__item__heading__main">
                      <div class="pricing-plans__features-mobile-list__item__heading__title">Pro</div>
                      <div class="pricing-plans__list__item__content__price">
                        <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? proPriceAnnual : proPriceMonthly }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        isOpenedProFeaturesList && !checkIsAbilityToUpdatePlan(getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY))
                      "
                      class="price-list__plans-list__item__heading__label-action__text"
                      @click="onChoosePlan(getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY))"
                    >
                      <template v-if="getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY).isCurrentPlan">{{
                        $t('pricing.currentPlan')
                      }}</template>
                      <template v-else-if="getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY).isNewPlan">{{
                        $t(planChangeText(getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY)))
                      }}</template>
                      <template v-else>{{ $t(planChangeText(getCurrentPlanById(isYearly ? M_PROFESSIONAL_ANNUAL : M_PROFESSIONAL_MONTHLY))) }} </template>
                    </div>
                  </div>

                  <div v-if="isOpenedProFeaturesList" class="pricing-plans__features-props">
                    <div v-for="featureSection in featuresList" :key="featureSection.title">
                      <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

                      <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
                        <div class="pricing-plans__features-props__item__value">
                          <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                          <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
                        </div>

                        <div class="pricing-plans__features-props__item__marks-list">
                          <div class="pricing-plans__features-props__item__marks-list__item">
                            <template v-if="feature.isCustomField">
                              {{ feature.customTextPro }}
                            </template>

                            <template v-else>
                              <ThinCheckIcon v-if="feature.isPro" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                              <img
                                v-else
                                src="@/assets/images/register-funnel/cross.svg"
                                alt="Check"
                                class="pricing-plans__features-props__item__marks-list__item__icon"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pricing-plans__features-mobile-list__item">
                  <div class="pricing-plans__features-mobile-list__item__heading">
                    <div class="pricing-plans__features-mobile-list__item__heading__main">
                      <div class="pricing-plans__features-mobile-list__item__heading__title">Enterprise</div>
                      <div class="pricing-plans__list__item__content__price">
                        <!-- <div class="pricing-plans__list__item__content__price__currency">{{ currentCurrencySymbol }}</div>
                        <div class="pricing-plans__list__item__content__price__amount">
                          {{ isYearly ? enterprisePriceAnnual : enterprisePriceMonthly
                          }}<span class="pricing-plans__list__item__content__price__amount__period">/mo</span>
                        </div> -->

                        <div class="pricing-plans__list__item__content__price__amount pricing-plans__list__item__content__price__amount--custom-price">
                          {{ $t('pricing.customQuote') }}
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        isOpenedEnterpriseFeaturesList && !checkIsAbilityToUpdatePlan(getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY))
                      "
                      class="price-list__plans-list__item__heading__label-action__text"
                      @click="onChoosePlan(getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY))"
                    >
                      <template v-if="getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY).isCurrentPlan">{{
                        $t('pricing.currentPlan')
                      }}</template>
                      <template v-else-if="getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY).isNewPlan">{{
                        $t(planChangeText(getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY)))
                      }}</template>
                      <template v-else>{{ $t(planChangeText(getCurrentPlanById(isYearly ? M_ENTERPRISE_ANNUAL : M_ENTERPRISE_MONTHLY))) }} </template>
                    </div>
                  </div>

                  <div v-if="isOpenedEnterpriseFeaturesList" class="pricing-plans__features-props">
                    <div v-for="featureSection in featuresList" :key="featureSection.title">
                      <div class="pricing-plans__features-props__item-heading">{{ featureSection.title }}</div>

                      <div v-for="feature in featureSection.items" :key="feature.title" class="pricing-plans__features-props__item">
                        <div class="pricing-plans__features-props__item__value">
                          <div class="pricing-plans__features-props__item__value__title">{{ feature.title }}</div>
                          <div class="pricing-plans__features-props__item__value__description">{{ feature.description }}</div>
                        </div>

                        <div class="pricing-plans__features-props__item__marks-list">
                          <div class="pricing-plans__features-props__item__marks-list__item">
                            <template v-if="feature.isCustomField">
                              {{ feature.customTextEnterprise }}
                            </template>

                            <template v-else>
                              <ThinCheckIcon v-if="feature.isEnterprise" class="pricing-plans__features-props__item__marks-list__item__icon" color="#3034F7" />
                              <img
                                v-else
                                src="@/assets/images/register-funnel/cross.svg"
                                alt="Check"
                                class="pricing-plans__features-props__item__marks-list__item__icon"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pricing-plans__features-mobile-list__button" @click="returnToAllPlans">
                  <ArrowLongIcon class="mr-2" color="rgb(135, 136, 148)" :direction="'left'" />

                  Return to All Plans
                </div>
              </div>
            </template>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { paddle } from '@/mixins/paddle'
import { currency } from '@/mixins/currency'
import { msubscription } from '@/mixins/msubscription'

import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import ThinCheckIcon from '@/components/icons/ThinCheckIcon'
import AmpersandIcon from '@/components/icons/AmpersandIcon'
import ArrowLongIcon from '@/components/icons/ArrowLongIcon'
import ConnectModal from '@/components/ConnectModal.vue'
import Mollie from './Mollie.vue'
import Promocode from './Promocode.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  mixins: [paddle, currency, msubscription],
  components: {
    ThinCheckIcon,
    AmpersandIcon,
    CloseCircleIcon,
    ConnectModal,
    Mollie,
    Promocode,
    ArrowLongIcon
  },
  data: (state) => ({
    isOpenedFeaturesList: false,
    isOpenedBasicFeaturesList: false,
    isOpenedProFeaturesList: false,
    isOpenedEnterpriseFeaturesList: false,

    M_MONTHLY_FREE: 'M_MONTHLY_FREE',
    M_ANNUAL_FREE: 'M_ANNUAL_FREE',
    M_BASIC_MONTHLY: 'M_BASIC_MONTHLY',
    M_BASIC_ANNUAL: 'M_BASIC_ANNUAL',
    M_PROFESSIONAL_MONTHLY: 'M_PROFESSIONAL_MONTHLY',
    M_PROFESSIONAL_ANNUAL: 'M_PROFESSIONAL_ANNUAL',
    M_ENTERPRISE_MONTHLY: 'M_ENTERPRISE_MONTHLY',
    M_ENTERPRISE_ANNUAL: 'M_ENTERPRISE_ANNUAL',

    showMollieComponent: false,
    updateKey: Math.random().toString(36).substring(2, 15),
    ID_FREE_PLAN: null,
    isOpenedContactSalesDialog: false,
    isLoadingSubscriptionPlans: false,
    isYearly: true,
    annualSubscription: null,
    licenses: 0,
    updateUrl: null,
    currencySymbol: '€',
    maxIncomingRequestsForFreePlan: 20,
    selectedPlan: null,
    selectedUpcomingPlanId: null,
    showConfirmation: false,
    showCancelConfirmation: false,
    showMollieConfirmation: false,
    showMollieUpgradeConfirmation: false,
    showMollieCancelConfirmation: false,
    featuresList: [
      {
        title: state.$i18n.t('features.generalFeatures'),
        items: [
          {
            name: state.$i18n.t('features.includedUsers'),
            description: state.$i18n.t('features.forIndividualsAndTeams'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: '5',
            customTextBasic: '5',
            customTextPro: '5',
            customTextEnterprise: state.$i18n.t('pricing.biggerTeams')
          },
          {
            name: state.$i18n.t('features.liveContactWidget'),
            description: state.$i18n.t('features.easyChatPhoneAndVideoCommunicationWithCustomers'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.emailSupport'),
            description: state.$i18n.t('features.getEmailSupportFromContactNowExperts'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactFormToCollectLeads'),
            description: state.$i18n.t('features.contactFormToCollectLeadsDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.screensharing'),
            description: state.$i18n.t('features.screensharingDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.aiPoweredPreQualification'),
            description: state.$i18n.t('features.aiPoweredPreQualificationDescription'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextBasic: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextPro: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextEnterprise: state.$i18n.t('features.vegaCostPerVisitor')
          }
        ]
      },
      {
        title: state.$i18n.t('features.proSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.fullStackCalendar'),
            description: state.$i18n.t('features.appointmentSchedulingAndEasyBooking'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.onlineMeeting'),
            description: state.$i18n.t('features.setUpMeetingsWithMoreParticipants'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactViaQrCode'),
            description: state.$i18n.t('features.contactViaQrCodeDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.unlimitedVisitorsIdentification'),
            description: state.$i18n.t('features.unlimitedVisitorsIdentificationDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.ABTesting'),
            description: state.$i18n.t('features.ABTestingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.customBranding'),
            description: state.$i18n.t('features.customBrandingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          }
        ]
      },
      {
        title: state.$i18n.t('features.enterpriseSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.routing'),
            description: state.$i18n.t('features.routingDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.expertAnalytics'),
            description: state.$i18n.t('features.expertAnalyticsDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.prioritySupport'),
            description: state.$i18n.t('features.prioritySupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          }
        ]
      }
    ]
  }),

  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    generateRightTranslations(string, locale) {
      return locale === 'de' ? `Sparen ${string}` : `${string} Save`
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyData: 'company',
      subscription: 'subscription',
      subscriptionPlans: 'subscription_plans',
      refresh: 'refresh',
      allUsers: 'users',
      currency: 'currency',
      appliedPromoCode: 'appliedPromoCode'
    }),

    isFirstInvoice() {
      return this.companyData && !this.companyData.mollieSubscriptionId
    },

    isOpenedFeaturesListOnMobile() {
      return this.isOpenedBasicFeaturesList || this.isOpenedProFeaturesList || this.isOpenedEnterpriseFeaturesList
    },

    users() {
      return this.allUsers.filter((x) => x.company === this.activeUserInfo.company)
    },

    NEW_SUBSCRIPTION_PLAN_ID() {
      return this.companyData.newMollieSubscriptionPlanId || this.companyData.newSubscriptionPlanId
    },

    changePlanConfirmationTitle() {
      if (this.selectedPlan) {
        const billed = this.selectedPlan.billed ? ` - ${this.selectedPlan.billed}` : ''
        return `${this.$i18n.t(this.planChangeText(this.selectedPlan))} (${this.selectedPlan.title}${billed})`.toUpperCase()
      }
      return ''
    },
    ACTIVE_FROM() {
      if (this.companyData && this.companyData.expiryDate) {
        return `${this.$i18n.t('pricing.activeFrom')} ${dayjs.unix(this.companyData.expiryDate.seconds).add(-1, 'day').format('DD-MM-YYYY')}`
      }
      return ''
    },
    DOWNGRADED_FROM() {
      if (this.companyData && this.companyData.expiryDate && this.companyData.paymentStatus !== 'on-trial') {
        return `${this.$i18n.t('pricing.downgradeSubscriptionMessage')} ${dayjs.unix(this.companyData.expiryDate.seconds).add(-1, 'day').format('DD-MM-YYYY')}`
      }
      return `${this.$i18n.t('pricing.downgradeSubscriptionMessage')} ${dayjs().utc().format('DD-MM-YYYY')}`
    },
    DOWNGRADED_FROM_TEXT() {
      if (this.companyData && this.companyData.expiryDate && this.companyData.paymentStatus !== 'on-trial' && this.companyData.newMollieSubscriptionPlanId) {
        return `${this.$i18n.t('pricing.downgradeSubscriptionMessage')} ${dayjs
          .unix(this.companyData.expiryDate.seconds)
          .add(-1, 'day')
          .locale(this.$i18n.locale)
          .format('LL')}`
      }
      return null
    },
    CURRENT_PLAN() {
      const plan = this.subscriptionPlans.find((x) => x.isCurrentPlan)
      return plan
    },

    CURRENT_PLAN_LEVEL() {
      return this.CURRENT_PLAN ? this.CURRENT_PLAN.level : 0
    },

    HAS_SUBSCRIPTION() {
      return this.HAS_PADDLE_SUBSCRIPTION || this.HAS_MOLLIE_SUBSCRIPTION || this.HAS_INVOICE_SUBSCRIPTION
    },

    HAS_PADDLE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.subscriptionId) &&
        Boolean(this.companyData.subscriptionPlanId) &&
        this.companyData.paymentStatus === 'subscription' &&
        this.companyData.planType !== 'free' &&
        this.companyData.planType !== 'trial'
      )
    },

    HAS_CANCELLED_PADDLE_MOLLIE_SUBSCRIPTION() {
      return this.HAS_CANCELLED_PADDLE_SUBSCRIPTION || this.HAS_CANCELLED_MOLLIE_SUBSCRIPTION
    },

    HAS_CANCELLED_PADDLE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.subscriptionId) &&
        Boolean(this.companyData.subscriptionPlanId) &&
        this.companyData.paymentStatus === 'deleted' &&
        this.companyData.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds))
      )
    },

    HAS_CANCELLED_MOLLIE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.subscriptionPlanId) &&
        Boolean(this.companyData.mollieSubscriptionId) &&
        Boolean(this.companyData.mollieCustomerId) &&
        this.companyData.paymentStatus === 'subscription-cancelled' &&
        this.companyData.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds))
      )
    },

    HAS_INVOICE_SUBSCRIPTION() {
      if (!this.companyData) return false
      return Boolean(this.companyData.currentInvoiceId)
    },

    HAS_MOLLIE_SUBSCRIPTION() {
      return (
        this.companyData &&
        Boolean(this.companyData.subscriptionPlanId) &&
        Boolean(this.companyData.mollieSubscriptionId) &&
        Boolean(this.companyData.mollieCustomerId) &&
        this.companyData.paymentStatus !== 'subscription-cancelled' &&
        this.companyData.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.companyData.expiryDate.seconds))
      )
    },

    HAS_FREE_SUBSCRIPTION() {
      if (!this.company) return false
      return Boolean(this.company.subscriptionPlanId && this.company.subscriptionPlanId.indexOf('FREE') > -1 && this.company.paymentStatus === 'subscription')
    },

    IS_FREE_PLAN() {
      return this.companyData && this.companyData.planType === 'free' && this.companyData.paymentStatus === 'subscription'
    },

    locale() {
      return this.$i18n.locale
    },

    filteredSubscriptionPlans() {
      const filterSubscriptionPlans = this.subscriptionPlans.filter((plan) => {
        if (plan.enabled) {
          const isSuitablePlan = !plan.billing_type || (this.isYearly && plan.billing_type === 'year') || (!this.isYearly && plan.billing_type === 'month')

          if (isSuitablePlan) {
            return plan
          }
        }
      })

      return filterSubscriptionPlans
    }
  },

  async created() {
    this.$serverBus.$on('close-mollie-form', () => {
      this.onMollieFormClose()
    })
  },

  async mounted() {
    await this.onLoadPricingInformation()
    window.Paddle.Setup({
      vendor: this.getVendorId()
    })
    this.$serverBus.$on('invoice-created', () => {
      this.showMollieComponent = false
      window.location.reload(true)
    })
    const currencyText = this.companyData && this.companyData.currency ? this.companyData.currency.text : this.currency.text || 'EUR'
    const currencySymbol = this.getCurrencySymbol(currencyText)
    if (currencyText && currencySymbol && this.symbols[currencyText] === currencySymbol) {
      this.updateCurrency({ text: currencyText, symbol: currencySymbol })
    }
  },

  beforeDestroy() {
    this.$serverBus.$off('invoice-created')
    this.$serverBus.$off('close-mollie-form')
  },

  watch: {
    locale() {
      this.setBaseSubscriptionPlans()
    },
    'refresh.plans'() {
      if (this.refresh.plans) {
        this.onLoadPricingInformation()
      }
    },
    async 'companyData.subscriptionPlanId'(after, before) {
      if (after && after !== before) {
        if (this.companyData.subscriptionPlanId && this.companyData.paymentStatus !== 'subscription-cancelled') {
          this.setCurrentPlan(this.companyData.subscriptionPlanId)
          this.onLoadPricingInformation()
        }
        await this.getSubscriptionInfo()
      }
    },
    'companyData.newSubscriptionPlanId'() {
      this.onLoadPricingInformation()
    },
    'companyData.newMollieSubscriptionPlanId'() {
      this.setCurrentPlan(this.companyData.subscriptionPlanId)
      this.onLoadPricingInformation()
    },
    'companyData.planType'() {
      this.setBaseSubscriptionPlans()
      this.onLoadPricingInformation()
    },

    'companyData.currency'() {
      if (this.companyData.currency && this.companyData.currency.text && this.companyData.currency.symbol) {
        this.updateCurrency({ text: this.companyData.currency.text, symbol: this.companyData.currency.symbol })
      }
    },

    subscription() {
      if (this.subscription && this.subscription.currency) {
        this.updateCurrency({ text: this.subscription.currency.text, symbol: this.subscription.currency.symbol })
      }

      if (this.subscription && this.subscription.metadata && this.subscription.metadata.currency) {
        this.updateCurrency({ text: this.subscription.metadata.currency.text, symbol: this.subscription.metadata.currency.symbol })
      }
    }
  },

  methods: {
    ...mapMutations({
      updateSubscriptionPlans: 'UPDATE_SUBSCRIPTION_PLANS',
      updateRefreshState: 'UPDATE_REFRESH_STATE',
      updateSubscription: 'UPDATE_SUBSCRIPTION',
      updateCurrency: 'UPDATE_CURRENCY',
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      updateChosenPlan: 'UPDATE_CHOSEN_PLAN'
    }),

    SHOW_PLAN_AS_FREE(planId) {
      if (['M_ANNUAL_FREE', 'M_MONTHLY_FREE'].includes(planId)) {
        return true
      }

      let retval = false
      const plans = [...this.filteredSubscriptionPlans]
      const currentPlan = plans.find((el) => el.id === planId)
      if ((!this.HAS_MOLLIE_SUBSCRIPTION && !this.HAS_INVOICE_SUBSCRIPTION) || this.companyData.paymentStatus === 'on-trial') {
        retval = currentPlan.isFree
      } else if (currentPlan.id === this.companyData.subscriptionPlanId || currentPlan.ids.includes(this.companyData.subscriptionPlanId)) {
        retval = this.companyData.subscriptionPlanId.indexOf('FREE') > -1
      } else if (
        currentPlan.isFree &&
        this.HAS_MOLLIE_SUBSCRIPTION &&
        currentPlan.id !== this.HAS_MOLLIE_SUBSCRIPTION &&
        !currentPlan.ids.includes(this.companyData.subscriptionPlanId)
      ) {
        retval = true
      }
      return retval
    },

    getCurrentPlanById(planId) {
      const plans = [...this.filteredSubscriptionPlans]
      const currentPlan = plans.find((el) => el.id === planId)
      return currentPlan
    },

    returnToAllPlans() {
      this.isOpenedBasicFeaturesList = false
      this.isOpenedProFeaturesList = false
      this.isOpenedEnterpriseFeaturesList = false
    },

    setPlanInterval(isYearly) {
      this.isYearly = isYearly
    },
    SET_PLAN_CLASS(plan, position) {
      if (position === 'heading-label') {
        if (plan.isCurrentPlan) {
          return 'price-list__plans-list__item__heading__label-action_active'
        }
        if (plan.isNewPlan) {
          return 'price-list__plans-list__item__heading__label-action_inactive'
        }
        return ''
      }

      if (position === 'list-label') {
        if (plan.isCurrentPlan) {
          return 'price-list__plan__heading__label-action_active'
        }
        if (plan.isNewPlan) {
          return 'price-list__plan__heading__label-action_inactive'
        }
        return ''
      }
    },
    async onLoadPricingInformation() {
      await this.$vs.loading()

      this.isLoadingSubscriptionPlans = true

      this.ID_FREE_PLAN = this.getFreePlanId()

      /* Get Max Calls per month from settings */
      const bannerDoc = await this.$db.collection('settings').doc('banner').get()
      if (bannerDoc && bannerDoc.data() && bannerDoc.data().maxIncomingRequestsForFreePlan) {
        this.maxIncomingRequestsForFreePlan = bannerDoc.data().maxIncomingRequestsForFreePlan
      }

      this.setBaseSubscriptionPlans()

      if (this.companyData && this.companyData.licenses) {
        this.licenses = this.companyData.licenses
      } else if (this.companyData && !this.companyData.licenses) {
        const usersRef = await this.$db.collection('users').where('company', '==', this.activeUserInfo.company).where('isActive', '==', true).get()
        const licenses = usersRef.docs.length

        this.licenses = licenses
        await this.$db.collection('company').doc(this.activeUserInfo.company).set({ licenses }, { merge: true })
      }
      if (!this.subscription) {
        await this.getSubscriptionInfo()
        setTimeout(async () => {
          await this.$vs.loading.close()
          this.isLoadingSubscriptionPlans = false
        }, 2000)
      } else {
        await this.$vs.loading.close()
        this.isLoadingSubscriptionPlans = false
      }
    },
    async onConfirmCancelMolliePlan() {
      await this.$vs.loading()
      if (this.selectedUpcomingPlanId) {
        await this.$db
          .collection('company')
          .doc(this.activeUserInfo.company)
          .set({ newMollieSubscriptionPlanId: this.$firebase.firestore.FieldValue.delete() }, { merge: true })

        this.setIsNewPlanInSubscriptionPlans()
        this.selectedUpcomingPlanId = null
        this.showMollieCancelConfirmation = false
      }
      await this.$vs.loading.close()
    },
    onCloseCancelMolliePlan() {
      this.showMollieCancelConfirmation = false
    },
    async onConfirmCancelPlan() {
      await this.$vs.loading()
      if (this.selectedUpcomingPlanId) {
        await this.$db
          .collection('company')
          .doc(this.activeUserInfo.company)
          .set({ newSubscriptionPlanId: this.$firebase.firestore.FieldValue.delete() }, { merge: true })

        this.setIsNewPlanInSubscriptionPlans()
        this.selectedUpcomingPlanId = null
        this.showCancelConfirmation = false
      }
      await this.$vs.loading.close()
    },
    onCloseCancelPlan() {
      this.showCancelConfirmation = false
    },
    async onConfirmUpgradeMolliePlan() {
      await this.updateMollieSubscription()
      this.showMollieUpgradeConfirmation = false
    },
    async onConfirmChangeMolliePlan() {
      await this.$vs.loading()
      if (this.companyData && this.companyData.paymentStatus === 'on-trial') {
        await this.updateMollieSubscription()
      } else {
        await this.$db
          .collection('company')
          .doc(this.activeUserInfo.company)
          .set({ newMollieSubscriptionPlanId: this.selectedPlan.id.toString() }, { merge: true })
        this.setIsNewPlanInSubscriptionPlans(this.selectedPlan.id.toString())
      }
      this.selectedPlan = null
      this.showMollieConfirmation = false
      await this.$vs.loading.close()
    },
    onCloseChangeMolliePlan() {
      this.selectedPlan = null
      this.showMollieConfirmation = false
    },
    onCloseUpgradeMolliePlan() {
      this.selectedPlan = null
      this.showMollieUpgradeConfirmation = false
    },
    onConfirmChangePlan() {
      const plan = { ...this.selectedPlan }
      this.selectedPlan = null
      this.showConfirmation = false
      this.selectPlanAction(plan)
    },
    onCloseChangePlan() {
      this.selectedPlan = null
      this.showConfirmation = false
    },
    getPlanSummaryInfo(plan) {
      if (plan.price === 'free' || plan.price === 'kostenlos') {
        return `${this.maxIncomingRequestsForFreePlan} ${plan.cost}`
      }
      return plan.cost
    },
    async onMollieFormClose() {
      this.selectedPlan = null
      this.showMollieComponent = false
    },

    setCurrentPlan(planId) {
      const plans = [...this.subscriptionPlans]
      plans.forEach((x) => {
        x.isCurrentPlan = x.id === planId
      })
      this.updateSubscriptionPlans(plans)
    },

    onDisplayError(message) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(message),
        color: 'danger',
        time: 10000
      })
    },

    async updateMollieSubscription() {
      try {
        await this.$vs.loading()
        const mollieUpdateSubscription = this.$functions.httpsCallable('mollieUpdateSubscription')
        const payment = {
          companyId: this.companyData.id,
          mollieCustomerId: this.companyData.mollieCustomerId,
          mollieSubscriptionId: this.companyData.mollieSubscriptionId,
          metadata: {
            companyId: this.companyData.id,
            userId: this.activeUserInfo.uid,
            planId: this.selectedPlan.id,
            customerId: this.companyData.mollieCustomerId,
            currency: this.currency
          },
          redirectUrl: location.href,
          planId: this.selectedPlan.id,
          description: this.selectedPlan.description
        }
        if (this.appliedPromoCode && this.appliedPromoCode.id && this.appliedPromoCode.enabled) {
          payment.metadata.appliedPromoCodeId = this.appliedPromoCode.id
        }
        const response = await mollieUpdateSubscription(payment)
        if (response && response.data && response.data.error && response.data.message) {
          this.onDisplayError(response.data.message)
        }
      } catch (error) {
        this.onDisplayError(error.message)
      } finally {
        await this.$vs.loading.close()
      }
    },

    notifyMessage(title, text, color) {
      this.$vs.notify({
        title,
        text,
        color
      })
    },

    onChoosePlan(plan) {
      if (plan.isEnterprisePlan) {
        window.open('https://app.letsconnect.at/pages/qr-connect?cid=9Q6YqYKY5NCONiVpUgPY', '_blank').focus()
        return
      }

      this.selectedPlan = plan
      if (!this.HAS_SUBSCRIPTION && !this.HAS_FREE_SUBSCRIPTION && !plan.isFree) {
        this.updateChosenPlan(plan)
        this.updateShowChangePlanUI(true)
      } else {
        this.subscribeToPlan(plan)
      }
    },

    async subscribeToPlan(plan) {
      if (plan.isCurrentPlan) {
        return
      }

      if (this.HAS_CANCELLED_PADDLE_MOLLIE_SUBSCRIPTION) {
        return
      }

      //#region PADDLE SUBSCRIPTION
      if (this.HAS_PADDLE_SUBSCRIPTION) {
        this.notifyMessage('', this.$i18n.t('vue.alreadyHaveASubscription'), 'warning')
        return
      }
      //#endregion

      //#region INVOICE SUBSCRIPTION
      if (
        this.companyData.subscriptionPlanId &&
        this.companyData.paymentMethod === 'invoice' &&
        this.companyData.currentInvoiceId &&
        this.companyData.paymentStatus !== 'on-trial'
      ) {
        return
      }

      if (this.companyData && this.companyData.currentInvoiceId && this.companyData.paymentStatus === 'on-trial') {
        await this.$vs.loading()
        await this.$db.collection('pathadvice-invoices').doc(this.companyData.currentInvoiceId).set({ selectedPlanId: plan.id }, { merge: true })
        this.notifyMessage(this.$i18n.t('vue.success'), this.$i18n.t('vue.subscriptionUpdated'), 'green')
        await this.$vs.loading.close()
        return
      }
      //#endregion

      if (plan.isNewPlan) {
        this.selectedUpcomingPlanId = this.companyData.newMollieSubscriptionPlanId
        this.showMollieCancelConfirmation = true
        return
      }

      this.selectedPlan = plan

      if (!this.HAS_MOLLIE_SUBSCRIPTION) {
        const totalActiveUsers = this.users.length
        const remainingUsers = plan.totalUsers - totalActiveUsers
        if (remainingUsers < 0) {
          this.$vs.notify({
            title: this.$i18n.t('vue.error'),
            text: this.$i18n.t('vue.subscribeUsersCountMessage').replace('[[SELECTED_PLAN_NAME]]', `(${plan.description})`),

            color: 'red',
            time: 8800
          })
        } else if (plan.isFree) {
          this.subscribeToFreePlan(plan)
        } else {
          this.showMollieComponent = true
          this.setupMollieComponents()
        }

        return
      }

      //#region MOLLIE SUBSCRIPTION
      if (this.HAS_MOLLIE_SUBSCRIPTION) {
        if (plan.isFree) {
          this.notifyMessage('', this.$i18n.t('vue.alreadyHaveASubscription'), 'warning')
          return
        }
      }

      if (this.companyData && this.companyData.paymentStatus !== 'deleted') {
        const upgrade = plan.level > this.CURRENT_PLAN_LEVEL

        if (upgrade) {
          this.updateChosenPlan(plan)
          this.showMollieUpgradeConfirmation = true
        } else {
          const currentPlanId = this.companyData.subscriptionPlanId
          const currentPlan = this.subscriptionPlans.find((x) => x.id === currentPlanId)

          const totalActiveUsers = this.users.length
          const remainingUsers = plan.totalUsers - totalActiveUsers

          if (remainingUsers < 0) {
            this.$vs.notify({
              title: this.$i18n.t('vue.error'),
              text: this.$i18n
                .t('vue.downgradeUsersCountMessage')
                .replace('[[NEW_PLAN_NAME]]', `(${plan.description})`)
                .replace('[[CURRENT_PLAN_NAME]]', `(${currentPlan.description})`),

              color: 'red',
              time: 8800
            })
          } else {
            this.showMollieConfirmation = true
          }
        }
      }
      //#endregion
    },

    subscribePlan(plan) {
      if (plan.isCurrentPlan) {
        return
      }
      if (plan.isNewPlan) {
        this.selectedUpcomingPlanId = this.companyData.newSubscriptionPlanId
        this.showCancelConfirmation = true
        return
      }

      if (
        !this.HAS_PADDLE_SUBSCRIPTION ||
        plan.isEnterprisePlan ||
        (this.companyData && this.companyData.paymentStatus === 'deleted' && plan.category !== 'free')
      ) {
        this.selectPlanAction(plan)
      } else {
        this.selectedPlan = plan
        this.showConfirmation = true
      }
    },

    planChangeText(plan) {
      if (plan.isEnterprisePlan) {
        return 'pricing.contactSales'
      }
      if (plan.isNewPlan) {
        return 'pricing.cancelPlan'
      } else if ((!this.HAS_MOLLIE_SUBSCRIPTION && !this.companyData.currentInvoiceId) || (this.companyData && this.companyData.paymentStatus === 'deleted')) {
        return 'pricing.subscribeNow'
      } else {
        return plan.level < this.CURRENT_PLAN_LEVEL ? 'pricing.downgrade' : 'pricing.upgradeNow'
      }
    },
    setCurrencySymbol(rp) {
      this.currencySymbol = this.getCurrencySymbol(rp)
    },
    planPrice(plan) {
      if (this.subscription && this.subscription.planAmount && this.subscription.selectedPlanId === plan.id && !this.companyData.IS_LOTTERY_WINNER) {
        if (plan.billing_type === 'year' && this.companyData.GENERATE_NEXT_INVOICE_DATE_BY_PLAN) {
          return `${this.subscription.planAmount / 12}`
        } else {
          const planAmount =
            typeof this.subscription.planAmount === 'string' && this.subscription.planAmount.indexOf('.00') > -1
              ? parseInt(this.subscription.planAmount).toFixed(0)
              : this.subscription.planAmount
          return planAmount
        }
      }
      if (
        this.subscription &&
        this.subscription.metadata &&
        this.subscription.metadata.planAmount &&
        plan.id === this.subscription.metadata.planId &&
        !this.companyData.IS_LOTTERY_WINNER
      ) {
        return this.subscription.metadata.planAmount
      }

      let currency = this.companyData && this.companyData.currency ? this.companyData.currency.text : this.currency.text || 'EUR'
      let currencySymbol = this.getCurrencySymbol(currency)
      if (currency && currencySymbol && plan.recurring_price && plan.recurring_price[currency]) {
        let price = plan.recurring_price[currency]
        if (currency === 'EUR') {
          price = parseFloat(plan.recurring_price[currency]).toFixed(0)
        }
        if (this.companyData && this.companyData.IS_LOTTERY_WINNER && plan.lottery_price) {
          const planAmount =
            this.subscription && this.subscription.planAmount && plan.billing_type === 'year' ? (Number(this.subscription.planAmount) / 12).toFixed(2) : null
          price = planAmount || (parseFloat(plan.lottery_price['EUR']) / 12).toFixed(2)
          currency = 'EUR'
          currencySymbol = this.getCurrencySymbol(currency)
        }
        return `${price}`
      }

      if (typeof plan.price === 'number') {
        if (plan.billing_type === 'year') {
          return `${plan.price / 12}`
        } else {
          return `${plan.price}`
        }
      }

      return plan.price
    },
    selectPlanAction(plan) {
      if (plan.isEnterprisePlan) {
        this.isOpenedContactSalesDialog = true

        return
      } else if (plan.isCurrentPlan) {
        return
      } else if (plan.isNewPlan) {
        return
      }

      this.selectPayment(plan)
    },

    async subscribeToFreePlan(plan) {
      await this.$vs.loading()

      const mollieFreeSubscription = this.$functions.httpsCallable('mollieFreeSubscription')
      const res = await mollieFreeSubscription({
        planId: plan.ids.find((x) => x.indexOf('FREE') > -1)
      })

      if (res && res.data && res.data.error) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t(`error.${res.data.message}`),
          color: 'danger'
        })
      }

      await this.$vs.loading.close()
    },

    async updatePaymentDetails() {
      await this.$vs.loading()

      const updateLink = this.$functions.httpsCallable('companyPaymentUpdateLink')
      const res = await updateLink({
        companyId: this.activeUserInfo.company
      })

      this.updateUrl = res.data.url

      window.Paddle.Checkout.open({
        override: this.updateUrl,
        successCallback: this.updateSuccess
      })

      await this.$vs.loading.close()
    },

    async checkoutSuccess() {
      this.$vs.notify({
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.subscriptionAdded'),
        color: 'green'
      })
      this.$serverBus.$emit('successful-payment')
    },

    async updateSuccess() {
      this.$vs.notify({
        title: this.$i18n.t('vue.success'),
        text: this.$i18n.t('vue.subscriptionUpdated'),
        color: 'green'
      })
      this.$serverBus.$emit('successful-payment')
    },

    async createNewSubscription(link) {
      window.Paddle.Checkout.open({
        override: link,
        disableLogout: true,
        successCallback: this.checkoutSuccess
      })
    },

    async selectPayment(plan) {
      const id = plan.id
      if (!id) {
        return
      }

      await this.$vs.loading()

      if (plan) {
        this.annualSubscription = plan.billing_type === 'year'
      }

      /* If user already has a subscription then update the subscription */
      if (this.HAS_PADDLE_SUBSCRIPTION && this.companyData && this.companyData.paymentStatus !== 'deleted') {
        const upgrade = plan.level > this.CURRENT_PLAN_LEVEL
        if (upgrade) {
          const companyUpdateSubscription = this.$functions.httpsCallable('companyUpdateSubscription')
          const res = await companyUpdateSubscription({
            subscription_id: this.companyData.subscriptionId,
            plan_id: plan.id,
            user_id: this.activeUserInfo.uid,
            bill_immediately: true
          })
          if (res && res.data && res.data.subscription_id) {
            this.updateSuccess()
          }
        } else {
          await this.$db.collection('company').doc(this.activeUserInfo.company).set({ newSubscriptionPlanId: plan.id.toString() }, { merge: true })

          this.setIsNewPlanInSubscriptionPlans(plan.id.toString())
        }
      } else {
        const companyPaymentLink = this.$functions.httpsCallable('companyPaymentLink')
        const res = await companyPaymentLink({
          product_id: id,
          discountable: 1,
          userId: this.activeUserInfo.uid
        })
        const url = res.data.url
        this.createNewSubscription(url)
      }

      await this.$vs.loading.close()
    },

    setIsNewPlanInSubscriptionPlans(planId) {
      /* Get the plans in a new array object to avoid mutating the object */
      const plans = this.subscriptionPlans.map((x) => {
        return { ...x }
      })

      plans.map((x) => {
        if (x.id.toString() === planId) {
          x.isNewPlan = true
        } else {
          x.isNewPlan = false
        }
      })

      this.updateSubscriptionPlans(plans)
    },

    checkIsAbilityToUpdatePlan(plan) {
      if (
        this.company &&
        !this.company.subscriptionPlanId &&
        !this.company.currentInvoiceId &&
        !this.company.mollieCustomerId &&
        !this.company.subscriptionId &&
        this.company.paymentStatus !== 'on-trial'
      ) {
        return false
      }
      return plan.category === 'free' && !plan.isCurrentPlan && !this.HAS_PADDLE_SUBSCRIPTION
    },

    setBaseSubscriptionPlans() {
      let plans = this.getBaseSubscriptionPlans()
      if (this.companyData && this.companyData.subscriptionPlanId && this.companyData.planType !== 'free' && this.companyData.planType !== 'trial') {
        plans = plans.map((plan) => {
          if (plan.id.toString() === this.NEW_SUBSCRIPTION_PLAN_ID || plan.ids.includes(this.NEW_SUBSCRIPTION_PLAN_ID)) {
            plan.isNewPlan = true
          } else {
            plan.isNewPlan = false
          }
          if (plan.id.toString() === this.companyData.subscriptionPlanId || plan.ids.includes(this.companyData.subscriptionPlanId)) {
            plan.isCurrentPlan = true
            this.isYearly = plan.billing_type === 'year'
          } else {
            plan.isCurrentPlan = false
          }

          if (plan.billing_type === 'year') {
            plan.billed = plan.billing_interval ? this.$i18n.t(plan.billing_interval) : this.$i18n.t('pricing.billedAnnually')
          }
          if (plan.billing_type === 'month') {
            plan.billed = this.$i18n.t('pricing.billedMonthly')
          }

          return plan
        })
      } else {
        plans = plans.map((plan) => {
          const planId = plan.id.toString()
          const freePlans = [this.ID_FREE_PLAN.toString(), 'M_ANNUAL_FREE', 'M_MONTHLY_FREE']
          if (freePlans.includes(planId)) {
            plan.isCurrentPlan = this.IS_FREE_PLAN
            plan.isNewPlan = false
          } else {
            plan.isCurrentPlan = false
            plan.isNewPlan = false
          }

          if (plan.billing_type === 'year') {
            plan.billed = plan.billing_interval ? this.$i18n.t(plan.billing_interval) : this.$i18n.t('pricing.billedAnnually')
          }
          if (plan.billing_type === 'month') {
            plan.billed = this.$i18n.t('pricing.billedMonthly')
          }

          return plan
        })
      }
      plans = plans.filter((x) => x.enabled)
      this.updateSubscriptionPlans(plans)
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}
@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}

.pa-bk-secondary {
  background: #275d73 !important;
  border-radius: 10px;
  color: #fff !important;
}
.price-list-dialog {
  .vs-popup--content {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: rgb(218, 225, 231);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track:vertical {
      background-color: rgb(248, 248, 248);
      border-radius: 6px;
    }
  }
}
</style>

<style lang="scss" scoped>
$main-color: rgb(18, 89, 141);
$secondary-color: rgb(59, 134, 247);
$light-color: rgb(255, 255, 255);

.price-list {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  &-mollie-close-button {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-left: -1px;
    }
  }

  &__heading {
    text-align: center;
    color: #262629;

    &-features {
      margin-top: 80px;
    }

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
      text-transform: capitalize;
    }

    &__description {
      margin-top: 15px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  &__plan {
    &__heading {
      position: relative;
      padding: 0 20px;
      text-align: center;
      height: 120px;

      &__title {
        color: #262629;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        text-transform: uppercase;
      }

      &__price {
        margin-top: 10px;
        height: 50px;
        max-width: 230px;
        color: #262629;
        font-size: 36px;
        line-height: 40px;
        font-weight: 700;
        text-transform: uppercase;

        &_text {
          font-size: 15px;
          line-height: 19px;
          text-transform: none;
        }
      }

      &__label-action {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);

        &__text {
          padding: 4px 15px;
          min-width: 145px;
          color: $light-color;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-align: center;
          text-transform: capitalize;
          background: rgb(240, 169, 65);
          border-radius: 6px;
          &.price-list__plans-list__item__heading__label-action__text--active {
            background: #3034f7;
          }

          &:hover {
            opacity: 0.95;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
            transition: all 0.2s linear;
            cursor: pointer;
          }
        }

        &_active {
          .price-list__plan__heading__label-action {
            &__text {
              background: rgb(91, 179, 40);

              &:hover {
                opacity: 1;
                box-shadow: none;
                cursor: default;
              }
            }
          }
        }

        &_inactive {
          .price-list__plan__heading__label-action {
            &__text {
              background: #f05541;

              &:hover {
                opacity: 1;
                box-shadow: none;
                cursor: pointer;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
  }

  &__plans {
    margin-top: 30px;

    &-list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
      }

      &__item {
        display: flex;
        overflow-wrap: break-word;
        flex-direction: column;
        margin-top: 43px;
        width: calc(100% / 4 - 24px / 4);

        @media (max-width: 991px) {
          margin-top: 26px;
          width: 100%;
          max-width: 480px;
        }

        &__downgrade {
          background: rgba(240, 85, 65, 0.3);
          margin-top: 20px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          border-radius: 6px;
        }

        &__heading {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 12px 12px 0 0;
          &.price-list__plans-list__item__heading--active {
            background: #3034f7;
            .price-list__plans-list__item__heading__title {
              color: #fff;
            }
          }

          &__title {
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 20px;
            line-height: 22px;
            text-align: center;
            letter-spacing: -0.111111px;
            text-transform: uppercase;
          }
        }

        &_best {
          border-color: rgb(240, 169, 65);
          border-top-left-radius: 0;

          .price-list__plans-list__item__heading {
            border-top-left-radius: 0;
          }
        }

        &__label-best {
          position: absolute;
          top: -2px;
          left: -2px;
          padding: 1px 5px;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          background: rgb(240, 169, 65);
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          transform: translateY(-100%);
          z-index: 2;
        }

        &__heading {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 48px;
          border-radius: 12px 12px 0 0;
          @media (max-width: 991px) {
            background: rgb(232, 246, 255);
          }

          &__title {
            height: 28px;
            color: #262629;
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            text-transform: capitalize;
          }

          &__price {
            display: flex;
            align-items: flex-end;
            color: #262629;
            font-family: 'Larsseit-Bold';
            font-size: 40px;
            line-height: 0.9;
            letter-spacing: -0.222222px;

            &_text {
              font-size: 15px;
              line-height: 19px;
              text-transform: none;
              &_container {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: center;

                &_interval {
                  align-self: flex-end;
                  justify-self: flex-end;
                  font-size: 25px;
                }
              }
            }
          }

          &__cost {
            margin-top: 20px;
            text-align: center;
            font-family: 'Larsseit-Medium';
            color: #262629;
            font-size: 15px;
            line-height: 15px;
            letter-spacing: -0.0833333px;
          }

          &__label-summary {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: -30px;
            text-align: center;
            color: #f05541;
            font-size: 0.8rem;
          }

          &__label-action {
            display: flex;
            justify-content: center;
            width: 100%;

            &__text {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50px;
              color: white;
              font-family: 'Larsseit-Bold';
              font-size: 18px;
              line-height: 22px;
              letter-spacing: -0.1px;
              margin-top: 14px;
              background: #82ceff;
              border-radius: 8px;
              text-transform: capitalize;
              width: 100%;
              &.price-list__plans-list__item__heading__label-action__text--active {
                background: #3034f7;
              }
              &.price-list__plans-list__item__heading__label-action__text--disabled {
                background: rgba(130, 206, 255, 0.3);
                &:hover {
                  opacity: 0.98;
                  box-shadow: none;
                  transition: all 0.2s linear;
                  background: #c7c7c7;
                  cursor: default;
                }
              }
              &:hover {
                opacity: 0.98;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                transition: all 0.2s linear;
                cursor: pointer;
              }
            }

            &_active {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #3034f7;

                  &:hover {
                    opacity: 1;
                    box-shadow: none;
                    cursor: default;
                  }
                }
              }
            }

            &_inactive {
              .price-list__plans-list__item__heading__label-action {
                &__text {
                  background: #f05541;

                  &:hover {
                    opacity: 1;
                    box-shadow: none;
                    cursor: pointer;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
                  }
                }
              }
            }
          }
        }

        &__body {
          flex: 1;
          position: relative;
          padding: 40px 30px 34px;
          width: 100%;
          background: rgb(244, 250, 255);
          border-radius: 12px;

          @media (max-width: 991px) {
            border-radius: 0 0 12px 12px;
          }

          @media (min-width: 992px) {
            &__optional {
              margin-top: 120px !important;
              &__position {
                position: absolute !important;
                bottom: -10px !important;
                left: 22px !important;
              }
            }
          }

          &__optional {
            margin-top: 50px;
            &--header {
              color: #262629;
              margin-bottom: 5px;
              font-size: 15px;
              font-weight: 700;
            }
          }

          &__options-list {
            margin-top: 23px;

            &__vega-option {
              margin-top: 10px !important;
              margin-bottom: 30px;
            }

            &__item {
              position: relative;
              margin-top: 15px;
              padding-left: 30px;
              color: #262629;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.0833333px;

              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              &--boldify {
                margin-top: 0;
                font-weight: 700;
              }
              &__title {
                font-weight: 600;
              }

              &__icon {
                position: absolute;
                top: 0;
                left: 0;
                font-weight: 600;
                color: #42b5ff;
                height: 18px;
              }

              &_previous {
                color: rgb(240, 169, 65);
                font-weight: 600;

                .price-list__plans-list__item__body__options-list__item {
                  &__icon {
                    color: rgb(240, 169, 65);
                  }
                }
              }

              &_more {
                font-weight: 600;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }

  &__features-table {
    display: flex;
    position: relative;
    margin-top: 40px;
    overflow: hidden;

    &__options-list {
      margin-top: 120px;
      width: 270px;
      flex-shrink: 0;

      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 93px;
        left: 0;
        width: 275px;
        height: 27px;
        background: #275d73;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &__item {
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 15px;
        height: 43px;
        color: #262629;
        font-size: 12px;
        line-height: 1.1;
        font-weight: 500;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 2px;
          background: #275d73;
          border-radius: 6px;
        }

        &-wrapper {
          &:first-child {
            .price-list__features-table__options-list__item {
              &:first-child {
                &::before {
                  display: none;
                }
              }
            }
          }
        }

        &__tooltip {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    &__plans-list {
      display: flex;
      flex-shrink: 0;
      flex-grow: 1;
      width: calc(100% - 290px);
      overflow-x: auto;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        height: 10px;
      }

      &::-webkit-scrollbar-thumb:horizontal {
        background-color: rgb(218, 225, 231);
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track:horizontal {
        background-color: rgb(248, 248, 248);
        border-radius: 6px;
      }

      &__item {
        flex-shrink: 0;
        flex-grow: 1;
        min-width: 210px;

        &:last-child {
          .price-list__features-table__plans-list__item {
            &__heading {
              &::before {
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
              }
            }
          }
        }

        &__heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          height: 120px;
          background: $light-color;

          &::before {
            content: '';
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 27px;
            background: #275d73;
          }
        }

        &__row {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          height: 43px;
          color: #262629;
          font-weight: 600;

          &__icon {
            color: #262629;
            font-weight: 600;
          }
        }

        &_active {
          background: rgba(59, 134, 247, 0.1);
        }
      }
    }
  }
}

.checkbox {
  margin: 0 15px;

  &.vs-switch {
    &-active {
      background: #275d73;
    }
  }

  &-label {
    color: #262629;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.label-sale {
  padding: 1px 5px;
  color: rgb(91, 179, 40);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  background: rgba(91, 179, 40, 0.1);
  border-radius: 6px;
}
</style>

<style lang="scss">
.pricing-plans {
  margin: 0 auto;
  width: 1024px;
  max-width: 100%;

  &__heading {
    display: flex;
    justify-content: center;

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 142px;
      height: 54px;
      border: 1px solid rgb(217, 219, 221);
      border-radius: 8px 0px 0px 8px;

      &:hover {
        background: rgb(252, 254, 255);
        cursor: pointer;
      }

      &:last-of-type {
        border-radius: 0px 8px 8px 0px;
      }

      &__title {
        color: rgb(83, 83, 91);
        font-family: 'Larsseit-Medium';
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.0833333px;
      }

      &__label {
        color: rgb(217, 219, 221);
        font-family: 'Larsseit-Extra-Bold';
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.944444px;
        text-transform: uppercase;
      }

      &--current {
        border-color: rgb(130, 206, 255);

        .pricing-plans {
          &__heading {
            &__button {
              &__title {
                color: rgb(38, 38, 41);
                font-family: 'Larsseit-Bold';
              }

              &__label {
                color: rgb(66, 181, 255);
              }
            }
          }
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-top: 43px;
      width: calc(100% / 3 - 24px / 3);

      @media (max-width: 991px) {
        margin-top: 26px;
        width: 100%;
        max-width: 480px;
      }

      &__heading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 12px 12px 0 0;

        @media (max-width: 991px) {
          background: rgb(232, 246, 255);
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.111111px;
        }
      }

      &__content {
        flex: 1;
        position: relative;
        padding: 40px 30px 34px;
        width: 100%;
        background: rgb(244, 250, 255);
        border-radius: 12px;

        @media (max-width: 991px) {
          border-radius: 0 0 12px 12px;
        }

        &__label {
          position: absolute;
          top: 11px;
          left: 0;
          width: 100%;
          color: rgb(66, 181, 255);
          font-family: 'Larsseit-Extra-Bold';
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 1.13333px;
          text-transform: uppercase;

          &--desktop {
            @media (max-width: 991px) {
              display: none;
            }
          }

          &--mobile {
            display: none;

            @media (max-width: 991px) {
              display: block;
            }
          }
        }

        &__price {
          display: flex;
          justify-content: center;

          &__currency {
            padding-top: 3px;
            padding-right: 3px;
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Medium';
            font-size: 22px;
            line-height: 1;
            letter-spacing: -0.122222px;
          }

          &__amount {
            display: flex;
            align-items: flex-end;
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 40px;
            line-height: 0.9;
            letter-spacing: -0.222222px;

            &__period {
              color: rgb(83, 83, 91);
              font-family: 'Larsseit-Medium';
              font-size: 14px;
              line-height: 1;
              text-align: right;
              letter-spacing: -0.0777778px;
            }

            &--custom-price {
              color: #262629;
              text-transform: none;
              font-family: 'Larsseit-Bold';
              font-weight: 700;
              text-align: center;
              font-size: 20px;
              min-height: 36px;
            }
          }
        }

        &__worth {
          margin-top: 6px;
          color: rgb(66, 181, 255);
          font-family: 'Larsseit-Medium';
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: -0.0888889px;
        }

        &__type {
          display: none;
          margin-top: 24px;

          @media (max-width: 991px) {
            display: flex;
          }

          .pricing-plans__heading__button {
            &__label {
              color: rgb(163, 205, 232);
            }

            &--current {
              background: rgb(255, 255, 255);

              .pricing-plans__heading__button {
                &__label {
                  color: rgb(66, 181, 255);
                }
              }
            }
          }
        }

        &__users {
          margin-top: 20px;
          font-family: 'Larsseit-Medium';
          color: rgb(38, 38, 41);
          font-size: 15px;
          line-height: 15px;
          letter-spacing: -0.0833333px;
          text-align: center;
        }

        &__button-main {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 14px;
          height: 50px;
          color: rgb(255, 255, 255);
          font-family: 'Larsseit-Bold';
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.1px;
          background: rgb(130, 206, 255);
          border-radius: 8px;

          &:hover {
            background: rgb(66, 181, 255);
            transition: all 0.2s linear;
            cursor: pointer;
          }

          &-wrapper {
            display: flex;
            justify-content: center;
          }

          &__icon {
            margin-left: 11px;
            width: 18px;
          }

          &--desktop {
            @media (max-width: 991px) {
              display: none;
            }
          }

          &--mobile {
            display: none;
            margin-top: 17px;

            @media (max-width: 991px) {
              display: flex;
            }
          }
        }

        &__features-list {
          margin-top: 23px;

          @media (max-width: 991px) {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
          }

          &__item {
            display: flex;
            align-items: flex-start;
            margin-top: 10px;

            &:first-of-type {
              margin-top: 0;
            }

            &__icon {
              margin-top: 5px;
              margin-right: 5px;
              min-width: 11px;
            }

            &__text {
              color: rgb(38, 38, 41);
              font-size: 15px;
              line-height: 18px;
              letter-spacing: -0.0833333px;

              &--strong {
                font-family: 'Larsseit-Bold';
              }
            }
          }
        }
      }

      &--popular {
        @media (max-width: 991px) {
          order: -1;
          margin-top: 0;
        }

        .pricing-plans {
          &__list {
            &__item {
              &__heading {
                background: rgb(48, 52, 247);

                &__title {
                  color: rgb(255, 255, 255);
                }
              }

              &__content {
                border-radius: 0 0 12px 12px;

                &__button-main {
                  background: rgb(48, 52, 247);

                  &:hover {
                    background: rgb(42, 45, 222);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__features-list {
    &-toggler {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      @media (max-width: 991px) {
        display: none;
      }

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.1px;

        &:hover {
          cursor: pointer;
        }

        &__icon {
          margin-left: 5px;
          width: 16px;
          height: 16px;
        }
      }

      &--popular {
        order: -1;
      }

      &--mobile {
        display: none;

        @media (max-width: 991px) {
          display: flex;
        }
      }
    }
  }

  &__features {
    margin-top: 40px;

    @media (max-width: 991px) {
      display: none;
    }

    &__heading {
      padding-left: 250px;

      &__list {
        display: flex;
        border: 1px solid #c8e2f3;
        border-bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 16px 18px;
          width: 33.33%;

          &__title {
            color: rgb(38, 38, 41);
            font-family: 'Larsseit-Bold';
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.111111px;
          }

          .pricing-plans__list__item__content {
            &__price {
              margin-top: 6px;

              &__currency {
                font-size: 19px;
              }

              &__amount {
                font-size: 35px;

                &__period {
                  font-size: 12px;
                }

                &--custom-price {
                  color: #262629;
                  text-transform: none;
                  font-family: 'Larsseit-Bold';
                  font-weight: 700;
                  text-align: center;
                  font-size: 20px;
                }
              }
            }

            &__button-main {
              display: inline-block;
              margin-top: 14px;
              padding: 8px 12px;
              height: auto;
              color: rgb(255, 255, 255);
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }

    &-props {
      &__item {
        display: flex;
        border: 1px solid #c8e2f3;
        border-top: 0;

        &-heading {
          padding: 10px 15px 10px 35px;
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.67px;
          background: rgb(232, 246, 255);
          border: 1px solid rgb(200, 226, 243);

          &--rounded {
            border-radius: 8px 0px 0px;
          }
        }

        &__value {
          padding: 10px 15px 10px 35px;
          width: 250px;
          border-right: 1px solid #c8e2f3;

          &__title {
            color: rgb(38, 38, 41);
            font-size: 16px;
            line-height: 1.25;
            font-family: 'Larsseit-Medium';
          }

          &__description {
            color: rgb(83, 83, 91);
            font-family: 'Larsseit-Regular';
            font-size: 13px;
            line-height: 1.643;
          }
        }

        &__marks-list {
          display: flex;
          flex: 1;

          &__item {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            color: rgb(48, 52, 247);
            font-family: 'Larsseit-Medium';
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &__features-mobile-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;

    &__item {
      margin-top: 6px;
      width: 100%;
      border: 1px solid rgb(200, 226, 243);
      border-radius: 8px;

      &--popular {
        order: -1;
        .pricing-plans__list__item__content__button-main {
          background: rgb(48, 52, 247);
        }
      }

      &__heading {
        padding: 12px 16px;

        &__main {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .pricing-plans__list__item__content__price {
          &__currency {
            padding-top: 0;
            font-size: 16px;
          }

          &__amount {
            font-size: 28px;

            &__period {
              font-size: 12px;
            }
            &--custom-price {
              color: #262629;
              text-transform: none;
              font-family: 'Larsseit-Bold';
              font-weight: 700;
              text-align: center;
              font-size: 17px;
            }
          }
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.133333px;
        }

        &__saving {
          margin-left: auto;
          margin-right: 13px;
          color: rgb(66, 181, 255);
          font-family: 'Larsseit-Medium';
          font-size: 15px;
          line-height: 16px;
          letter-spacing: -0.0833333px;
        }
      }

      .pricing-plans__features-props {
        &__item {
          &-heading {
            padding-left: 16px;
          }

          &__value {
            flex: 1;
            padding-left: 16px;
            width: auto;
            border-right: none;
          }

          &__marks-list {
            flex: none;
            padding-right: 5px;
            min-width: 35px;
          }
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      margin-top: 20px;
      color: rgb(135, 136, 148);
      font-family: 'Larsseit-Bold';
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.1px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
